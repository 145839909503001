<template>
  <div id="categoriesSlider" class="category">
    <div class="swiper-container">
      <div class="swiper-wrapper mainBox">
        <template v-for="(item, index) in list">
          <router-link
            v-if="item.image2"
            :key="index"
            :to="`/products/category/${item.id}?category_id=${item.id}`"
            class="swiper-slide categoryCard "
          >
            <img
              :src="`https://api.shahinbaba.ir/` + item.image2"
              alt="category"
            />
            <h3 class="categoryTitle">{{ item.title }}</h3>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/swiper-bundle.min";

export default {
  name: "CategoryCard",
  props: {
    list: Array,
    item: Object,
    scSize: String,
  },
  data() {
    return {
      slider: null,
    };
  },
  mounted() {
    this.initSlider();
  },
  methods: {
    initSlider(kill = false) {
      this.initSwiper("categoriesSlider", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper("#categoriesSlider .swiper-container", {
          spaceBetween: 5,
          //   centeredSlides: true,
          loop: false,
          speed: 500,
          centeredSlides: true,
          centeredSlidesBounds: true,
          autoplay: false,
          slidesPerView: "auto",
        });
      });
    },
  },
};
</script>

<style scoped>
.categoryCard {
  position: relative;
  overflow: hidden;
  --transition-duration: 1s;
  --transition-easing: ease;
  transition: transform var(--transition-duration) var(--transition-easing);
  padding: 10px;
}

.categoryCard::before {
  content: "";
  right: -50%;
}

.categoryCard:hover::before {
  background: rgba(255, 255, 255, 0.4);
  width: 25%;
  height: 100%;
  top: 0%;
  transform: translateY(calc(-50% - 2px));
  transform: skew(45deg);
  position: absolute;
  transition: right var(--transition-duration) var(--transition-easing);
  right: 150%;
}

.categoryCard img {
  box-shadow: 1px 3px 7px #999;
  width: 100%;
  transition: all 1s ease;
}

.categoryCard {
  width: max-content;
}
.categoryCard img {
  width: 113px;
  height: 113px;
  border-radius: 50%;
  object-fit: cover;
}
.mainBox {
  justify-content: center;
}
h3.categoryTitle {
  color: black;
  text-align: center;
  margin-top: 8px;
  font-size: 16px;

}
@media (max-width: 580px) {
  .categoryCard img {
    width: 75px;
    height: 75px;
  }
  h3.categoryTitle{
    font-size: 14px;

  }
}
</style>
<style>

</style>

<template>
  <section class="recommended-products " id="recommendToday">
    <!-- header -->
    <div class="recommended-products-tabs">
      <div
        id="todayList"
        class="container d-flex justify-content-between align-items-center"
      >
        <h3 class="fontsize-medium text-light m-0">
          <!-- {{ productTitle }} -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M12.79 21L3 11.21v2c0 .53.21 1.04.59 1.41l7.79 7.79c.78.78 2.05.78 2.83 0l6.21-6.21c.78-.78.78-2.05 0-2.83z"
            />
            <path
              fill="currentColor"
              d="M11.38 17.41c.39.39.9.59 1.41.59c.51 0 1.02-.2 1.41-.59l6.21-6.21c.78-.78.78-2.05 0-2.83L12.62.58C12.25.21 11.74 0 11.21 0H5C3.9 0 3 .9 3 2v6.21c0 .53.21 1.04.59 1.41zM5 2h6.21L19 9.79L12.79 16L5 8.21z"
            />
            <circle cx="7.25" cy="4.25" r="1.25" fill="currentColor" />
          </svg>
          <strong>محصولات</strong> پیشنهادی
        </h3>
        <!-- <router-link
          :to="link"
          class="seeMoreHomeLink fontsize-medium text-light"
          >مشاهده همه</router-link
        > -->
        <!-- <img id="bigSaleImage" src="@/assets/images/bigSale.png" alt="" /> -->
      </div>
    </div>
    <!-- carousel -->
    <div  class="recommended-content">
      <div class="recommended-content-item position-relative" id="home">
        <!-- <div :style="`min-height: ${minHeight}px`"> -->
        <div>
          <div
            class="swiper-container"
            v-if="!disabledSliders.includes('recommendedProductSlider')"
          >
            <div class="swiper-wrapper">
              <Product
                class="swiper-slide"
                :style="'max-width:' + maxWidth + 'px'"
                v-for="(product, index) in list"
                :key="index + 'index'"
                :product="product"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- buttons -->
    <div class="next-btn-holder d-none d-xl-block">
      <div class="next-btn">
        <div class="next-btn-icon"><RightChevIcon /></div>
      </div>
    </div>
    <div class="prev-btn-holder d-none d-xl-block">
      <div class="prev-btn">
        <div class="prev-btn-icon"><LeftChevIcon /></div>
      </div>
    </div>
  </section>
</template>

<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import debounce from "lodash/debounce";
import LeftChevIcon from "@/parts/Front/components/Icon/LeftChevIcon.vue";
import RightChevIcon from "@/parts/Front/components/Icon/RightChevIcon.vue";

let breakpoints = {
  300: {
    slidesPerView: 1.3,
    spaceBetween: 15,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  1200: {
    slidesPerView: 4.3,
    spaceBetween: 15,
  },
};

export default {
  name: "AmazingProductBox",
  components: { Product, LeftChevIcon, RightChevIcon },
  props: {
    productTitle: String,
    link: String,
    list: Array,
  },
  data() {
    return {
      minHeight: "unset",
      maxWidth: "unset",
      onResizeFunction: debounce(this.onResize, 150),
    };
  },
  watch: {
    list: {
      immediate: true,
      handler(products) {
        if (!products) {
          return;
        }
        this.initSlider(true);
      },
    },
  },
  mounted() {
    this.initSlider();
    this.setMaxWidth();
    window.addEventListener("resize", this.onResizeFunction);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeFunction);
  },
  computed: {
    products() {
      return this.$store.getters["front/getRecomendationToday"];
    },
    showCount() {
      return 16;
    },
  },
  methods: {
    initData() {
      if (!this.products) {
        this.$store.dispatch("front/getRecomendationTodayFromServer");
      }
    },
    onResize() {
      this.setMaxWidth();
    },
    setMaxWidth() {
      let slidesPerView = this.getSlidesPerView();
      let spaceBetween = slidesPerView.spaceBetween;
      slidesPerView = slidesPerView.slidesPerView;
      this.maxWidth =
        (document.querySelector(".recommended-products").getBoundingClientRect()
          .width -
          spaceBetween * slidesPerView) /
          slidesPerView +
        (window.innerWidth > 768 ? 2 : 0);
    },
    getSlidesPerView() {
      let c = Number.parseInt(window.innerWidth);
      let ok = 1;
      let max = -1;
      Object.keys(breakpoints).forEach((b) => {
        b = Number.parseInt(b);
        if (c > b && b > max) {
          ok = breakpoints[b];
          max = b;
        }
      });
      return ok;
    },
    initSlider(kill = false) {
      this.initSwiper("recommendedProductSlider", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper("#recommendToday .swiper-container", {
          spaceBetween: 10,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: "#recommendToday .next-btn",
            prevEl: "#recommendToday .prev-btn",
          },
          breakpoints,
        });
        setTimeout(() => {
          this.minHeight = document
            .querySelector("#recommendToday .swiper-container")
            .getBoundingClientRect().height;
        }, 1700);
      });
    },
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
  },
};
</script>

<style scoped>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.recommended-products {
  background-color: #ff3c60;
  border-radius: 25px;
  padding: 0 40px 0 40px;
  position: relative;
  margin-bottom: 7rem;
  margin-top: 2rem;
  width: 90%;
}
.swiper-slide {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.recommended-content {
  position: relative;
  top: 70px;
}
.swiper-container {
  animation: fade 450ms;
}
.recommended-products-tabs {
  position: relative;
  top: 40px;
  right: 0;
}
.prev-btn-holder {
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateX(1px);
}
.next-btn-holder {
  position: absolute;
  top: 50%;
  left: 100%;
}
.prev-btn {
  background-color: #ff3c60;
  height: 140px;
  width: 120px;
  position: relative;
  -webkit-clip-path: polygon(
    93% 15%,
    100% 0,
    100% 50%,
    100% 100%,
    93% 85%,
    80% 70%,
    73% 60%,
    70% 50%,
    73% 40%,
    80% 30%
  );
  clip-path: polygon(
    93% 15%,
    100% 0,
    100% 50%,
    100% 100%,
    93% 85%,
    80% 70%,
    73% 60%,
    70% 50%,
    73% 40%,
    80% 30%
  );
}
.prev-btn-icon {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
}
.next-btn {
  background-color: #ff3c60;
  position: relative;
  height: 140px;
  width: 120px;
  -webkit-clip-path: polygon(
    7% 15%,
    0 0,
    0 50%,
    0 100%,
    7% 85%,
    20% 70%,
    27% 60%,
    30% 50%,
    27% 40%,
    20% 30%
  );
  clip-path: polygon(
    7% 15%,
    0 0,
    0 50%,
    0 100%,
    7% 85%,
    20% 70%,
    27% 60%,
    30% 50%,
    27% 40%,
    20% 30%
  );
}
.next-btn-icon {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
}

.seeMoreHomeLink {
  border-radius: 20px;
  border: 1px solid #fff;
  padding: 6px 16px 4px 16px;
  font-size: 14px !important;
  transition: all 0.3s;
}
.seeMoreHomeLink:hover {
  color: #000 !important;
  background-color: #fff;
}
@media (max-width: 1199px) {
  .recommended-products {
    border-radius: 20px;
    padding: 0;
  }
  .recommended-content {
    position: relative;
    top: 80px;
    padding-inline: 20px;
  }
  .next-btn-holder {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateX(-1px);
  }
}
@media (max-width: 540px) {
  .recommended-products {
    border-radius: 0;
    padding: 0;
  }
}
#todayList {
  position: relative;
}
img#bigSaleImage {
  position: absolute;
  left: -85px;
  width: 160px;
  z-index: 1;
  top: -100px;
}
#recommendToday {
  background: linear-gradient(45deg, #ff00c68c, #6947aca8);
  margin-right: 7%;
  width: 85%;
}
#recommendToday .next-btn {
  background-color: #6947aca8;
}
#recommendToday .prev-btn {
  background-color: #ee71d8;
}
@media (max-width: 425px) {
  img#bigSaleImage {
    position: absolute;
    left: 0px;
    width: 100px;
    z-index: 1;
    top: -60px;
  }
}
#recommendToday .swiper-slide {
  margin-left: 8px;
}
</style>

<template>
  <div>
    <Header />

    <slider />
    <div class="">
      <CategoryCard v-if="homeData" :list="homeData.special_categories" />
      <todayDiscount
        v-if="homeData && homeData.suggestions && homeData.suggestions.length"
        :list="homeData.suggestions"
      />
      <section id="bigSaleBanners" v-if="homeData && advertise">
        <DynamicLinker :item="advertise.bigSale" class="button">
          <img :src="advertise.bigSale.picture" alt="" />
        </DynamicLinker>
        <DynamicLinker :item="advertise.bigSale1" class="button">
          <img :src="advertise.bigSale1.picture" alt="" />
        </DynamicLinker>
      </section>
      <template v-if="homeData">
        <!-- <template v-if="homeData">
          <AmazingProductBox
            v-if="homeData.mostDiscount.length"
            :productTitle="'حراج شگفت انگیز'"
            :products="homeData.mostDiscount"
            :link="`/products/?sort=most_discount`"
          />
        </template> -->
        <RecommendedProductSlider />
        <section id="newProductBanner" v-if="advertise">
          <DynamicLinker :item="advertise.newProducts" class="button">
            <img :src="advertise.newProducts.picture" alt="" />
          </DynamicLinker>
        </section>
        <ProductsSlider
          v-for="(item, index) in homeData.specialProducts"
          :key="item.header"
          :productTitle="item.header"
          :uniqId="`productSliderSection${index}`"
          :products="item.products"
          :link="`/products/category/${item.catId}`"
        />
      </template>

      <template v-if="homeData">
        <ProductsSlider
          v-if="
            homeData.mostSalesLast7Days && homeData.mostSalesLast7Days.length
          "
          :productTitle="'پرفروش ترین ها'"
          :uniqId="`productSliderSectionMostSales`"
          :products="homeData.mostSalesLast7Days"
          :link="`/products/?sort=top_sales`"
        />
      </template>

      <br />

      <main class="main">
        <div>
          <ResponsiveBannerDouble
            class="w-100"
            :item="
              access($store.getters, 'front/getHomeData.advertise.center_1')
            "
            :item2="
              access($store.getters, 'front/getHomeData.advertise.center_2')
            "
          />
          <ResponsiveBanner
            class="w-100"
            :item="access($store.getters, 'front/getHomeData.advertise.center')"
            :mobile-item="
              access(
                $store.getters,
                'front/getHomeData.advertise.center_mobile'
              )
            "
          />
        </div>
        <Flashes
          v-if="
            $store.getters['front/getHomeData'] != null &&
              $store.getters['front/getHomeData'].flashes != ''
          "
          :flashes="$store.getters['front/getHomeData'].flashes"
        />
        <div>
          <ResponsiveBanner
            class="w-100"
            :item="access($store.getters, 'front/getHomeData.advertise.bottom')"
            :mobile-item="
              access(
                $store.getters,
                'front/getHomeData.advertise.bottom_mobile'
              )
            "
          />
        </div>
        <Banners :banners="banners[1]"></Banners>
      </main>
    </div>
  </div>
</template>
<script>
import ProductWarranty from "@/parts/Front/components/ProductWarranty";

import Banners from "@/parts/Front/components/Banners";
import Flashes from "@/parts/Front/components/FlashProducts";
import LastInstaPosts from "@/parts/Front/components/LastInstaPosts";
import ScrollToTop from "@/parts/Front/components/ScrollToTop";
import debounce from "lodash/debounce";
import ProductCategories from "@/parts/Front/components/ProductCategories";
import AboutHome from "@/parts/Front/components/home/AboutHome";
import RecommendedProductSlider from "@/parts/Front/components/RecommendedProductSlider";
import SingleBanner from "@/parts/Front/components/SingleBanner";
import ResponsiveBanner from "@/parts/Front/components/ResponsiveBanner";
import Services from "@/parts/Front/components/Services";
import RecommendedProduct from "@/parts/Front/components/RecommendedProduct";
import ResponsiveBannerDouble from "@/parts/Front/components/ResponsiveBannerDouble";
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker";

import RecommendedSpecialProductSlider from "@/parts/Front/components/RecommendedSpecialProductSlider";
import RecommendedSpecialProduct25 from "@/parts/Front/components/RecommendedSpecialProduct25";
import Slider from "@/parts/Front/nicecomponents/Slider.vue";
import ProductsSlider from "@/parts/Front/components/productsSlider.vue";
import AmazingProductBox from "@/parts/Front/components/AmazingProductBox.vue";
import todayDiscount from "@/parts/Front/components/todayDiscount.vue";
import HomeSlider from "@/parts/Front/components/home/HomeSlider.vue";
import CategoryCard from "@/parts/Front/nicecomponents/subcomponents/CategoryCard.vue";
export default {
  name: "Home",

  components: {
    RecommendedSpecialProduct25,
    ProductWarranty,
    ResponsiveBannerDouble,
    RecommendedProduct,
    RecommendedSpecialProductSlider,
    Services,
    ResponsiveBanner,
    SingleBanner,
    RecommendedProductSlider,
    AboutHome,
    ProductCategories,
    Banners,
    Flashes,
    LastInstaPosts,
    ScrollToTop,
    Slider,
    ProductsSlider,
    AmazingProductBox,
    HomeSlider,
    CategoryCard,
    todayDiscount,
    DynamicLinker,
  },
  data() {
    return {
      showInstagram: true,
      reRenderInstagramFunction: debounce(this.reRenderInstagram, 250),
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.reRenderInstagramFunction);
  },
  mounted() {
    this.CheckLoginFromOrder();
    this.CheckLoginFromCart();
    window.removeEventListener("resize", this.reRenderInstagramFunction);
    window.addEventListener("resize", this.reRenderInstagramFunction);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.reRenderInstagramFunction);
  },

  methods: {
    CheckLoginFromOrder() {
      if (
        this.$route.query.loginFromOrder &&
        !this.$route.fullPath.includes("loginModal")
      ) {
        if (document.getElementById("loginModalButton"))
          return document.getElementById("loginModalButton").click();
      }
    },
    CheckLoginFromCart() {
      if (
        this.$route.query.loginFromCart &&
        !this.$route.fullPath.includes("loginModal")
      ) {
        if (document.getElementById("loginModalButton"))
          return document.getElementById("loginModalButton").click();
      }
    },
    reRenderInstagram() {
      console.log("oki");
      this.showInstagram = false;
      this.$nextTick(() => {
        this.showInstagram = true;
      });
    },
  },
  computed: {
    advertise() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].advertise;
      } else {
        return null;
      }
    },
    homeData() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"];
      } else {
        return null;
      }
    },
    suggest() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].suggestions;
      } else {
        return [];
      }
    },
    banners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        let advertise = this.$store.getters["front/getHomeData"].advertise;
        return [
          [advertise.center, advertise.position2],
          [advertise.position3, advertise.position4],
        ];
      } else {
        return [
          [null, null],
          [null, null],
        ];
      }
    },
  },
};
</script>
<style>
p#homeDiscountAlert i {
  position: absolute;
  left: 15px;
  cursor: pointer;
  font-size: 24px;
}
p#homeDiscountAlert {
  background: #ba14aa;
  padding: 15px;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 18px;
}
#homeDiscountAlert span {
  animation: pulse 2.5s linear infinite;
}

@media (max-width: 768px) {
  #homeDiscountAlert span {
    animation: unset;
    text-align: center;
    padding: 0 16px;
  }
}
@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.7;
  }
  to {
    opacity: 1;
    text-shadow: 2px 0 black;

    transform: scale(1.5);
  }
}
@keyframes pulseM {
  from {
    transform: scale(1);
    opacity: 0.7;
  }
  to {
    opacity: 1;
    text-shadow: 2px 0 black;

    transform: scale(1.2);
  }
}
section#bigSaleBanners img {
  width: 100%;
}
section#bigSaleBanners {
  width: 85%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
section#bigSaleBanners img {
  transition: all 1.5s;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
section#bigSaleBanners img:hover {
  transform: scale(1.005);
}
section#newProductBanner img {
  width: 100%;
}
section#newProductBanner {
  width: 85%;
  margin: auto;
}
</style>

<template>
  <header class="main-header">
    <div id="shahin_instagram">
      <a href="https://www.instagram.com/shahre_kafsh_man" target="_blank">
        <img src="@/assets/images/instagram.gif" alt="shahin_instagram" />
        <span>با ما در اینستاگرام همراه باشید</span>
      </a>
    </div>
    <TopHeader @openRespMenu="openRespMenu"></TopHeader>
    <MainSearch></MainSearch>
    <!-- resp login Modal -->
    <RespLoginModal></RespLoginModal>
    <CartLists></CartLists>
    <RespMenu ref="respMenu"></RespMenu>
  </header>
</template>

<script>
import TopHeader from "@/parts/Front/components/TopHeader";
import MainSearch from "@/parts/Front/components/MainSearch";
import RespLoginModal from "@/parts/Front/components/RespLoginModal";
import RespMenu from "@/parts/Front/components/RespMenu";
import CartLists from "@/parts/Front/components/CartLists";

export default {
  name: "HeaderSecond",
  components: {
    TopHeader,
    MainSearch,
    RespLoginModal,
    RespMenu,
    CartLists,
  },
  methods: {
    openRespMenu() {
      this.$refs.respMenu.allCategoriesToggle();
    },
  },
};
</script>

<style scoped>
div#shahin_instagram a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  color: white;
  font-size: 20px;
}
div#shahin_instagram a img {
  width: 50px;
}

div#shahin_instagram {
  background: #1e8186;
}
div#shahin_instagram a span {
  animation: puls 1s infinite alternate;
}
@keyframes puls {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(1.2);
  }
}
</style>

<template>
  <section class="top-header" id="innerTopHeader">
    <a href=""></a>
    <div class="container d-flex justify-content-between align-items-baseline">
      <div class="d-flex align-items-baseline d-block mobileChangeFlex">
        <NavButton @click="$emit('openRespMenu')" />
        <HavinLogo />
        <Nav></Nav>
      </div>
      <div
        class="d-inline-block"
        :class="
          notifications && notifications.items && notifications.items.length
            ? 'ml-4'
            : 'ml-1'
        "
        v-if="mediaQueries.mobileSize"
      >
        <CartButton />
        <SearchButton class="bg-none ml-2 d-md-none okokok" />
        <HeaderNotification
          v-if="notifications"
          v-show="notifications.items && notifications.items.length"
          v-model="notifications"
        />
      </div>
      
      <div v-if="allowToShow" class="">
        
        <button
          type="button"
          class="d-md-inline-block d-none bg-none ml-2"
          @click="showCloseSearchBox()"
        >
          <svg
            width="27"
            height="27"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="11.7666"
              cy="11.7666"
              r="8.98856"
              stroke="#444"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.0183 18.4851L21.5423 22"
              stroke="#444"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <!-- نمایش اطلاعات کاربری -->

        <!-- نمایش آیکون اگر لاگین نبود -->

        <div
          class="d-inline-block"
          :class="
            notifications && notifications.items && notifications.items.length
              ? 'ml-4'
              : 'ml-1'
          "
          v-if="!mediaQueries.mobileSize"
        >
          <CartButton />
          <button
            v-if="!isLogin"
            type="button"
            id="loginModalButton"
            class="d-md-inline-block d-none bg-none ml-2"
            data-toggle="modal"
            data-target="#loginModal"
          >
            <svg
              width="27"
              height="27"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.5789"
                cy="7.27803"
                r="4.77803"
                stroke="#444"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.00002 18.7014C3.99873 18.3655 4.07385 18.0337 4.2197 17.7311C4.67736 16.8158 5.96798 16.3307 7.03892 16.111C7.81128 15.9462 8.59431 15.836 9.38217 15.7815C10.8408 15.6533 12.3079 15.6533 13.7666 15.7815C14.5544 15.8367 15.3374 15.9468 16.1099 16.111C17.1808 16.3307 18.4714 16.77 18.9291 17.7311C19.2224 18.3479 19.2224 19.064 18.9291 19.6808C18.4714 20.6419 17.1808 21.0812 16.1099 21.2918C15.3384 21.4634 14.5551 21.5766 13.7666 21.6304C12.5794 21.7311 11.3866 21.7494 10.1968 21.6854C9.92221 21.6854 9.65677 21.6854 9.38217 21.6304C8.59663 21.5773 7.81632 21.4641 7.04807 21.2918C5.96798 21.0812 4.68652 20.6419 4.2197 19.6808C4.0746 19.3747 3.99955 19.0401 4.00002 18.7014Z"
                stroke="#444"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <!-- <span>
              ورود / عضویت
            </span> -->
          </button>
          <HeaderNotification
            v-if="notifications"
            v-show="
              notifications && notifications.items && notifications.items.length
            "
            v-model="notifications"
          />
        </div>
        <LoginUser
          :class="notifications ? 'mr-3' : 'mr-2'"
          v-if="isLogin"
          :atlasTheme="true"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Nav from "@/parts/Front/components/Nav";
import LoginUser from "@/parts/Front/components/LoginUser";
import { cartListsOpen, openSearch } from "@/libraries/functions";
import findUrl from "@/libraries/links/LinkResolver";
import CartButton from "@/parts/Front/components/cart/CartButton";
import HeaderNotification from "@/parts/Front/components/notification/HeaderNotification";
import SearchButton from "./header/SearchButton";
import NavButton from "@/parts/Front/components/shared/NavButton";
import HavinLogo from "./shared/HavinLogo.vue";
export default {
  name: "TopHeader",
  inject: ["mediaQueries"],
  components: {
    NavButton,
    SearchButton,
    HeaderNotification,
    CartButton,
    LoginUser,
    Nav,
    HavinLogo,
  },
  data() {
    return {
      showOptions: false,
      allowToShow: true,
    };
  },
  methods: {
    changeAllowToShow() {
      this.allowToShow = window.innerWidth > 768 ? true : false;
    },
    findUrl,
    // cartListsCloseBody(){
    //   const cartLists=document.getElementsByClassName('cart-lists')[0];
    //   const cartListsCloseResp = document.getElementsByClassName('cart-lists-close-resp')[0];
    //   cartListsCloseResp.style.display="none";
    //   cartLists.style.left="-390px";
    // },
    cartListsOpen,
    showCloseSearchBox() {
      openSearch();
    },
  },
  mounted() {
    window.addEventListener("resize", this.changeAllowToShow);
    this.changeAllowToShow();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.changeAllowToShow);
  },
  computed: {
    menu() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].menu.header;
      } else {
        return this.$store.getters["front/getHomeData"];
      }
    },
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    notifications() {
      return this.$store.getters["front/getNotifications"];
    },
  },
};
</script>

<style scoped>
/* افزودن استایل به کدهای اضافه شده */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.childMenu {
  color: #666;
}
#menuSkelton {
  display: flex;
  justify-content: space-around;
  width: 300px;
}
#menuSkelton div {
  width: 50px;
  height: 30px;
}
</style>
<style>
#innerTopHeader nav .navbar-nav {
  margin-left: 2rem;
}
#innerTopHeader nav .navbar-nav {
  gap: 2px !important;
}
#innerTopHeader .logo-img img {
  width: 90% !important;
  height: 90% !important;
}
button#loginModalButton * {
 /* stroke: white;*/
}

button#loginModalButton {
  /*background: #c40050 !important;*/
  padding: 4px;
  color: white;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .mobileChangeFlex {
    /*flex-direction: row-reverse;
    justify-content: space-between;*/
    width: 50%;
  }
}
</style>

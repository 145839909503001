var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('slider'),_c('div',{},[(_vm.homeData)?_c('CategoryCard',{attrs:{"list":_vm.homeData.special_categories}}):_vm._e(),(_vm.homeData && _vm.homeData.suggestions && _vm.homeData.suggestions.length)?_c('todayDiscount',{attrs:{"list":_vm.homeData.suggestions}}):_vm._e(),(_vm.homeData && _vm.advertise)?_c('section',{attrs:{"id":"bigSaleBanners"}},[_c('DynamicLinker',{staticClass:"button",attrs:{"item":_vm.advertise.bigSale}},[_c('img',{attrs:{"src":_vm.advertise.bigSale.picture,"alt":""}})]),_c('DynamicLinker',{staticClass:"button",attrs:{"item":_vm.advertise.bigSale1}},[_c('img',{attrs:{"src":_vm.advertise.bigSale1.picture,"alt":""}})])],1):_vm._e(),(_vm.homeData)?[_c('RecommendedProductSlider'),(_vm.advertise)?_c('section',{attrs:{"id":"newProductBanner"}},[_c('DynamicLinker',{staticClass:"button",attrs:{"item":_vm.advertise.newProducts}},[_c('img',{attrs:{"src":_vm.advertise.newProducts.picture,"alt":""}})])],1):_vm._e(),_vm._l((_vm.homeData.specialProducts),function(item,index){return _c('ProductsSlider',{key:item.header,attrs:{"productTitle":item.header,"uniqId":("productSliderSection" + index),"products":item.products,"link":("/products/category/" + (item.catId))}})})]:_vm._e(),(_vm.homeData)?[(
          _vm.homeData.mostSalesLast7Days && _vm.homeData.mostSalesLast7Days.length
        )?_c('ProductsSlider',{attrs:{"productTitle":'پرفروش ترین ها',"uniqId":"productSliderSectionMostSales","products":_vm.homeData.mostSalesLast7Days,"link":"/products/?sort=top_sales"}}):_vm._e()]:_vm._e(),_c('br'),_c('main',{staticClass:"main"},[_c('div',[_c('ResponsiveBannerDouble',{staticClass:"w-100",attrs:{"item":_vm.access(_vm.$store.getters, 'front/getHomeData.advertise.center_1'),"item2":_vm.access(_vm.$store.getters, 'front/getHomeData.advertise.center_2')}}),_c('ResponsiveBanner',{staticClass:"w-100",attrs:{"item":_vm.access(_vm.$store.getters, 'front/getHomeData.advertise.center'),"mobile-item":_vm.access(
              _vm.$store.getters,
              'front/getHomeData.advertise.center_mobile'
            )}})],1),(
          _vm.$store.getters['front/getHomeData'] != null &&
            _vm.$store.getters['front/getHomeData'].flashes != ''
        )?_c('Flashes',{attrs:{"flashes":_vm.$store.getters['front/getHomeData'].flashes}}):_vm._e(),_c('div',[_c('ResponsiveBanner',{staticClass:"w-100",attrs:{"item":_vm.access(_vm.$store.getters, 'front/getHomeData.advertise.bottom'),"mobile-item":_vm.access(
              _vm.$store.getters,
              'front/getHomeData.advertise.bottom_mobile'
            )}})],1),_c('Banners',{attrs:{"banners":_vm.banners[1]}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }